import { DateTime } from 'luxon';
import { getNumberPrecision } from '@/composeables/helpers';

export default {
  methods: {
    percentage(val) {
      if (typeof val === 'undefined' || val === null || val === '' || val === 'N/A') {
        return '';
      }
      return parseFloat(val) > 0 ? `+${val}%` : `${val}%`;
    },

    decimals(value, minDecimals = 4, maxDecimals = 4) {
      if (typeof value === 'undefined' || value === null || value === '' || isNaN(value)) {
        return '';
      }
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals
      }).format(value);
    },

    roundSmallPercent(value, minDecimals = 2, maxDecimals = 4) {
      if (typeof value === 'undefined' || value === null || value === '') {
        return '';
      }
      if (value < 0.01) {
        minDecimals = 4;
        maxDecimals = 4;
      }
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals
      }).format(value);
    },

    rounded(value, decimalPlaces = 2) {
      if (typeof value === 'undefined' || value === null || value === '' || isNaN(value)) {
        return '';
      }
      var decimalNumber = typeof value === 'string' ? parseFloat(value) : value;

      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
      }).format(decimalNumber);
    },

    roundedPercent(value, decimalPlaces = 2) {
      const r = this.rounded(value, decimalPlaces);
      return r ? r + '%' : '';
    },

    usdCryptoPrice(value) {
      if (typeof value === 'undefined' || value === null || value === '') {
        return '';
      }
      var decimals = getNumberPrecision(value);
      var prefix = value < 0 ? '-' : '';
      value = value < 0 ? value * -1 : value;
      var price = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      }).format(value);
      if (price != 0) return prefix + '$' + price;

      price = this.formatSmallNumbers(value);
      return prefix + '$' + price;
    },

    btcCryptoPrice(value) {
      if (typeof value === 'undefined' || value === null || value === '') {
        return '';
      }
      var inSats = false;
      if (value <= 0.0001) {
        inSats = true;
        value = value * 100000000;
      }
      var rounded = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6
      }).format(value);

      return inSats ? `${rounded} sats` : `${rounded} BTC`;
    },

    truncate(value, maxChars) {
      if (value && value.length > maxChars) {
        return value.slice(0, maxChars) + '...';
      }
      return value;
    },

    readableDate(date) {
      if (!date || date == '0000-00-00') {
        return '';
      }
      return DateTime.fromISO(date).toFormat('LLL dd, yyyy');
    },

    readableDateTime(date) {
      if (!date) {
        return '';
      }
      return DateTime.fromJSDate(date).toFormat('LLL dd, yyyy h:mma');
    },

    minimalTimeWithinToday(date) {
      //return empty if null
      if (!date) {
        return '';
      }
      // if today then remove YYYY-MM-DD
      let datetime = null;
      if (isNaN(date)) {
        datetime = DateTime.fromISO(date);
      } else {
        datetime = DateTime.fromMillis(date);
      }
      if (datetime.toFormat('yyyy-MM-dd') === DateTime.now().toFormat('yyyy-MM-dd')) {
        return datetime.toFormat('h:mma');
      }

      // if same year, remove the year
      if (datetime.toFormat('yyyy') === DateTime.now().toFormat('yyyy')) {
        return datetime.toFormat('LLL dd, h:mm a');
      }
      return datetime.toFormat('LLL dd, yyyy h:mm a');
    },

    relativeTime(date) {
      return DateTime.fromISO(date).setZone('UTC').toRelative();
    },

    formatPrice(num) {
      const precision = getNumberPrecision(num);
      const dec = Math.pow(10, precision);
      var price = (Math.round(Number(num) * dec) / dec).toLocaleString(undefined, {
        minimumFractionDigits: precision
      });
      if (price != 0) return price;
      return this.formatSmallNumbers(num);
    },

    formatNumber(num, digits = 2, prefix = '') {
      if (typeof num === 'undefined' || num === null || num === '' || num == 0 || num == 0.0) {
        return '';
      }
      var si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'B' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
        { value: 1e21, symbol: 'Z' },
        { value: 1e24, symbol: 'Y' },
        { value: 1e27, symbol: 'R' },
        { value: 1e30, symbol: 'Q' }
      ];
      var negative = [
        { value: -1, symbol: '' },
        { value: -1e3, symbol: 'k' },
        { value: -1e6, symbol: 'M' },
        { value: -1e9, symbol: 'B' },
        { value: -1e12, symbol: 'T' },
        { value: -1e15, symbol: 'P' },
        { value: -1e18, symbol: 'E' },
        { value: -1e21, symbol: 'Z' },
        { value: -1e24, symbol: 'Y' },
        { value: -1e27, symbol: 'R' },
        { value: -1e30, symbol: 'Q' }
      ];
      var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var i;
      if (num >= 0) {
        for (i = si.length - 1; i > 0; i--) {
          if (num >= si[i].value) {
            break;
          }
        }
      } else {
        for (i = si.length - 1; i > 0; i--) {
          if (num <= negative[i].value) {
            break;
          }
        }
      }
      return prefix + (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
    },

    slugify(str, separator = '-') {
      return str
        .trim()
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, separator);
    },

    roundedBtc(value, decimalPlaces = 2) {
      if (typeof value === 'undefined' || value === null || value === '' || isNaN(value)) {
        return '';
      }
      var decimalNumber = typeof value === 'string' ? parseFloat(value) : value;
      if (value < 1) {
        decimalPlaces = getNumberPrecision(value);
      }
      let price_btc = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
      }).format(decimalNumber);
      if (price_btc != 0) return price_btc;

      return this.formatSmallNumbers(value);
    },

    titleize(str, separator = '-') {
      return str
        .split(separator)
        .map(word => {
          return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
        })
        .join(' ');
    },

    upperCase(str, separator = '-') {
      return str
        .split(separator)
        .map(word => {
          return word.toUpperCase();
        })
        .join(' ');
    },
    toChatTime(timestamp, format = '') {
      let datetime = '';
      if (typeof timestamp === 'string') {
        datetime = DateTime.fromISO(timestamp);
      } else {
        datetime = DateTime.fromJSDate(timestamp);
      }
      if (format == 'channel') {
        if (datetime > DateTime.now().startOf('day')) {
          return datetime.toLocaleString(DateTime.TIME_SIMPLE) + ' today';
        } else {
          return datetime.toFormat('h:mm a') + ' on ' + datetime.toFormat('MMMM dd, yyyy');
        }
      } else if (format == 'chat_start') {
        if (datetime > DateTime.now().startOf('day')) {
          return 'Today';
        } else {
          return datetime.toFormat('MMMM dd, yyyy');
        }
      } else if (datetime > DateTime.now().startOf('day')) {
        if (format === 'simple') {
          return datetime.toLocaleString(DateTime.TIME_SIMPLE);
        } else {
          return 'Today at ' + datetime.toLocaleString(DateTime.TIME_SIMPLE);
        }
      } else {
        return datetime.toFormat('MM/dd/yyyy');
      }
    },
    formatSmallNumbers(num) {
      var i = 1;
      let n = num,
        limit = Math.pow(10, 12);
      while (n < 10 && i <= limit) {
        n = n * 10;
        i *= 10;
      }
      if (i > limit) return 0;
      return (parseInt(n) / i).toFixed(Math.round(Math.log(i) / Math.log(10)));
    }
  }
};
